<template>
  <v-card>
    <v-toolbar color="grey darken-3" dark elevation="1">
      <v-toolbar-title>
        {{ `${title} ${isNew ? '(Створення)' : '(Редагування)'}` }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              class="grey darken-3"
              icon
              outlined
              :disabled="isNew"
              v-bind="attrs"
              v-on="on"
              :loading="getModalLoading"
              @click="menu = true"
          >
            <v-icon color="secondary">mdi-arrow-down-drop-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-list dense rounded nav>
          <v-list-item link class="px-4" @click.stop="deleteDialog">
            <v-list-item-icon>
              <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-form v-model="formValid" ref="form">
        <v-row>
          <v-col cols="6" md="6">
            <v-text-field type="text" filled label="Код" v-model="code"
                          hide-details
                          disabled
                          color="grey"/>
          </v-col>
          <v-col cols="6" md="6">
            <v-checkbox type="text" filled
                        label="Основний договір"
                        v-model="is_main"
                        hide-details
                        color="grey"/>
          </v-col>
          <v-col cols="12" md="12" v-if="!person_hash">
            <ACC_Contractor
                :value="owner_id"
                @autocompleteChange="ownerChange"
                color="grey"
                :select_type="'elements'"
            />
          </v-col>
          <v-col cols="12" md="12" v-if="person_hash">
            <v-text-field type="text" filled label="Контрагент" v-model="contractor_name"
                          hide-details disabled
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Назва коротка" v-model="short_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="short_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field type="text" filled label="Назва повна" v-model="full_name"
                          hide-details
                          required :rules="[v => !!v || 'Це поле є обов’язковим']"
                          :class="full_name ? '' : 'req-star'"
                          color="grey"/>
          </v-col>
          <v-col cols="6" md="6">
            <date-component v-model="date" filled :req="false"
                            label="Дата договору"/>
          </v-col>
          <v-col cols="6" md="6">
            <v-text-field type="text" filled
                          label="Номер договору"
                          v-model="number"
                          hide-details
                          color="grey"/>
          </v-col>
          <v-col cols="6" md="6">
            <v-checkbox type="text" filled
                        label="Покупець"
                        v-model="is_buyer"
                        hide-details
                        color="grey"/>
          </v-col>
          <v-col cols="6" md="6">
            <v-checkbox type="text" filled
                        label="ПДВ по оплаті"
                        v-model="tax_by_pay"
                        hide-details
                        color="grey"/>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn depressed text color="secondary darken-1" :loading="getModalLoading"
             @click="crud_item" class="button-accept">
        <v-icon left>mdi-content-save</v-icon>
        Зберегти
      </v-btn>
      <v-btn depressed text tile color="grey" @click="closeModal">
        <v-icon left>mdi-close</v-icon>
        Відмінити
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  CREATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS,
  UPDATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS,
  REMOVE_CONTRACTOR_CONTRACT_LIST_ELEMENTS
} from "@/store/actions/accounting/list";

import {ALERT_SHOW} from "@/store/actions/alert";
import {QUESTION_SHOW} from "@/store/actions/question";

import ModalComponentMixin from "@/mixins/modal_component";

const modalDeleteId = 'contractor_accounting_modal_delete'

export default {
  name: "HWP_Modal_Accounting_Contractor",
  mixins: [ModalComponentMixin],
  components: {
    ACC_Contractor: () => import("@/components/accounting/autocomplite/modal/ACC_Contractor")
  },
  data() {
    return {
      contractor_name: this.item.contractor_name || null,
      person_hash: this.item.person_hash || null,
      short_name: this.item.short_name,
      full_name: this.item.full_name,
      code: this.item.code || '',
      is_main: this.item.is_main || false,
      owner_id: this.item.owner_id || null,
      number: this.item.number || '',
      date: this.item.date || null,
      is_buyer: this.item.is_buyer || false,
      tax_by_pay: this.item.tax_by_pay || false,
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.itemId = this.item.id
      this.contractor_name = this.item.contractor_name || null
      this.short_name = this.item.short_name
      this.person_hash = this.item.person_hash || null
      this.full_name = this.item.full_name
      this.code = this.item.code
      this.number = this.item.number || ''
      this.is_main = this.is_main || false
      this.date = this.item.date || null
      this.owner_id = this.item.owner_id || null
      this.is_buyer = this.item.is_buyer || false
      this.tax_by_pay = this.item.tax_by_pay || false

      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення договору контрагента (${this.short_name})`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    ownerChange(payload) {
      this.owner_id = (payload || {})?.value || null
    },
    crud_item() {
      let error_text = `Контрагент, Коротка назва, повна назва - мають бути заповнені`
      this.formValid = this.$refs.form.validate()
      if (!this.formValid) {
        this.formValid = false
        this.$store.commit(ALERT_SHOW, {
          text: error_text,
          color: 'error lighten-1'
        })
        return
      }

      const payload = {
        short_name: this.short_name,
        full_name: this.full_name,
        code: this.code,
        date: this.date || null,
        number: this.number,
        owner_id: this.owner_id || null,
        person_hash: this.person_hash || null,
        is_main: this.is_main || false,
        is_buyer: this.is_buyer || false,
        tax_by_pay: this.tax_by_pay || false
      }

      if (this.isNew) {
        this.$store.dispatch(CREATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal({ emit: true })
              }
            })
      } else {
        payload.id = this.itemId
        this.$store.dispatch(UPDATE_CONTRACTOR_CONTRACT_LIST_ELEMENTS, payload)
            .then(el => {
              if (el) {
                this.closeModal()
              }
            })
      }
    },
    watch_item() {
      this.watcher = this.$watch(
          'item',
          {
            deep: true,
            immediate: true,
            handler(payload) {
              this.itemId = payload.id
              this.short_name = payload.short_name
              this.contractor_name = payload.contractor_name || null
              this.full_name = payload.full_name
              this.person_hash = payload.person_hash || null
              this.date = payload.date || null
              this.number = payload.number
              this.owner_id = payload.owner_id || null
              this.code = payload.code
              this.is_buyer = payload.is_buyer || false
              this.is_main = payload.is_main || false
              this.tax_by_pay = payload.tax_by_pay || false
            }
          }
      )
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(REMOVE_CONTRACTOR_CONTRACT_LIST_ELEMENTS, this.itemId)
                      .then(ok => {
                        if (ok) {
                          this.closeModal({ emit: true })
                        }
                      })
                }
              }
            }
          }
      )
    },
    watch_dialog() {
      this.dialog_watcher = this.$watch(
          'dialog',
          {
            immediate: true,
            handler(payload) {
              if (payload) {
                this.watch_item()
                this.watch_modal_answer()
              } else {
                if (this.watcher) {
                  this.watcher()
                }
                if (this.delete_watcher) {
                  this.delete_watcher()
                }
              }
            }
          }
      )
    }
  }
}
</script>
